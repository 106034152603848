import React, { Component } from 'react';
import { FaFacebook, FaInstagram, FaEnvelope } from "react-icons/fa";

export default class Footer extends Component {

    render() {
        return(
            <div className="row row-text">
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12"></div>
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 inner-wrapper">
                    
                    <div className="social-media-wrapper">
                        <a target="_blank" href="https://www.facebook.com/Cosmoblastos-AAFI-111050794124660"><FaFacebook /></a>
                        <a target="_blank" href="https://www.instagram.com/cosmoblastos_aafi/"><FaInstagram /></a>
                        <FaEnvelope />
                    </div>

                    <div className="intext-logo-img">
                        <img src="https://oximetro-cosmoblastos-images.s3.amazonaws.com/Sitio+web.003.png"></img>
                    </div>

                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12"></div>
            </div>
        )
    }
}