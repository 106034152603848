import React, { Component } from 'react';

export default class Descripcion extends Component {

    render() {
        return(
            <div className="row row-text" id="start-description">
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12"></div>
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 inner-wrapper">
                    <h3 className="inner-title rubik-text">Descripción.</h3>
                    <p className="inner-description rubik-text">
                        Uno de los principales objetivos de la división de Medicina Aeroespacial (Cosmoblastos) 
                        es el monitoreo continuo de la salud. Por lo tanto, nos encuentramos desarrollando un oxímetro 
                        no invasivo con el uso de Arduino, que permitirá la lectura de la concentración de oxígeno 
                        en sangre y la frecuencia cardiaca de los usuarios. Adicionalmente, 
                        se tiene planteado el desarrollo de una aplicación móvil que permita la visualización remota de los datos. 
                        En la actualidad, el dispositivo funciona a partir de la técnica de fotoplastimografía para estimar la frecuencia
                        cardiaca y la concentración de oxígeno en sangre, un Arduino Nano y 
                        una pantalla Oled.
                    </p>
                    <div className="inner-quote">
                        <p className="inner-quote-text rubik-text">
                            Uno de los principales objetivos de Cosmoblastos es el monitoreo continuo de la salud. 
                            Por lo tanto, nos encuentramos desarrollando un oxímetro 
                            no invasivo con el uso de Arduino.
                        </p>
                    </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12"></div>
            </div>
        )
    }
}