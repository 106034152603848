import React, { Component } from 'react';

export default class Disenio extends Component {

    render() {
        return(
            <div className="row row-text">
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12"></div>
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 inner-wrapper">
                    <h3 className="inner-title rubik-text">Etapa de diseño.</h3>
                    <div className="inner-image-wrapper">
                        <img src="https://oximetro-cosmoblastos-images.s3.amazonaws.com/oximetro02.jpeg"/>
                        <img src="https://oximetro-cosmoblastos-images.s3.amazonaws.com/etapa_disen%CC%83o2.jpeg"/>
                        <img src="https://oximetro-cosmoblastos-images.s3.amazonaws.com/componentes.jpeg"/>
                    </div>
                    <p className="inner-description rubik-text">
                        El proceso de diseño que se utilizó para el desarrollo de la propuesta partió de 
                        la generación de conceptos de solución, luego se realizó una selección de la mejor propuesta 
                        y por último 
                        se procedió al detalle del concepto. 
                    </p>
                    <p className="inner-description rubik-text">
                        Se consideró el uso de un Arduino Nano, una pantalla OLED que se caracteriza por su 
                        bajo consumo energético, un módulo bluetooth para la conexión inalámbrica, un sensor de 
                        fotoplestimografía, un interruptor, una carcasa de plástico y una batería de 9V.
                    </p>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12"></div>
            </div>
        )
    }
}