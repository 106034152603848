import './App.css';
import './Fonts/fonts.css';
import './Components/components.css';
import Landing from './Components/Landing';
import Descripcion from './Components/Descripcion';
import Disenio from './Components/Disenio';
import Manufactura from './Components/Manufactura';
import Resultado from './Components/Resultado';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App container">

      <Landing />
      <Descripcion />
      <Disenio />
      <Manufactura />
      <Resultado />
      <Footer />

    </div>
  );
}

export default App;
