import React, { Component } from 'react';

export default class Manufactura extends Component {

    render() {
        return(
            <div className="row row-text">
                <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12"></div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-12">
                    <div className="inner-side-image-wrapper">
                        <img src="https://oximetro-cosmoblastos-images.s3.amazonaws.com/oximetro03.png"/>
                    </div>
                </div>
                <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12 inner-wrapper">
                    <h3 className="inner-title rubik-text">Programación y manufactura.</h3>
                    <p className="inner-description rubik-text">
                        La aplicación se encarga de la visualización de datos en tiempo real y se programó 
                        con el uso de Ionic. Por ser una aplicación híbrida, se puede implementar dicho sistema tanto en dispositivos 
                        Android como IOS.  
                    </p>
                    <p className="inner-description rubik-text">
                        A través de la conexión bluetooth con el oxímetro, la aplicación capta los datos sensados 
                        y los almacena en una base de datos no relacional, todo con ayuda de herramientas de cómputo 
                        en la nube.
                    </p>
                </div>
                <div className="col-xxl-1 col-xl-1 col-lg-1 col-12"></div>
            </div>
        )
    }
}