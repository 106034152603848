import React, { Component } from 'react';

export default class Resultado extends Component {

    render() {
        return(
            <div className="row row-text">
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12"></div>
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 inner-wrapper">
                    <p className="inner-description rubik-text">
                    En relación al diseño de la carcasa se hizo uso de técnicas de CAD/CAE para el modelado geométrico 
                    y la validación de la propuesta. En específico se utilizó el software Fusion 360 e Inventor de Autodesk. 
                    Para la manufactura se llevó a cabo la impresión 3D de la carcasa con PLA.
                    </p>
                    <div className="intext-img">
                        <img src="https://oximetro-cosmoblastos-images.s3.amazonaws.com/Sitio+web.001.png"/>
                    </div>
                    <h3 
                        style={{margin: '30px 0'}}
                        className="inner-title rubik-text">Resultado final.</h3>
                    <div className="inner-video-wrapper">
                        <iframe src="https://drive.google.com/file/d/1n1lQ-vTroKue4CrqR78x2hmX6L-L67gx/preview" width="640" height="480"></iframe>
                    </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12"></div>
            </div>
        )
    }
}