import React, { Component } from 'react';

export default class Landing extends Component {

    handleVerMas = ()=> {
        document.getElementById('start-description').scrollIntoView();
    }

    render() {
        return(
            <div className="landing">
                <div className="row header">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="header-title-wrapper">
                            <p className="rubik-text header-title">Cosmoblastos - AAFI</p>
                            <p className="rubik-text header-subtitle">Medicina Aeroespacial</p>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="logo-wrapper">
                            <img src="https://oximetro-cosmoblastos-images.s3.amazonaws.com/Sitio+web.003.png" />
                        </div>
                    </div>
                </div>


                <div className="row main-content">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="img-wrapper">
                            <h1 className="background-text">Oxímetro</h1>
                            <img src="https://oximetro-cosmoblastos-images.s3.amazonaws.com/Sitio+web.001.png"/>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="text-wrapper">
                            <h2 className="rubik-text landing-title">Oxímetro. Proyecto de telemedicina</h2>
                            <p className="rubik-text description-title">En la división de medicina aeroespacial de la Asociación Aeroespacial de la Facultad de Ingeniería (AAFI) de la UNAM, nos encargamos del diseño y desarrollo de tecnología médica.</p>
                            <button onClick={this.handleVerMas} className="title-button" >Ver más</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}